import { template_image_dark } from "./image-links.js";
import { image_url_prefix } from "./config.js";
import { insertTextbeforeUrlExtention } from "../general/text-formatting.js";
import { res_types } from "./image-resolutions.js";

const project_information_data = {
  c007: {
    overview: {
      // sections: [
      //   [
      //     {
      //       headings: [
      //         {
      //           main: true,
      //           text: "Eagle House: Building a Sustainable Future in Collaboration with Tai Tarian",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Eagle House is more than just a development—it’s a vision brought to life by Castell Group in partnership with Tai Tarian housing association. Situated in a prime location, this project is designed to provide sustainable, affordable housing while supporting local businesses and enhancing community living.",
      //         },
      //       ],
      //     },
      //   ],
      //   [
      //     {
      //       headings: [
      //         {
      //           main: true,
      //           text: "A Holistic Development",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Eagle House comprises a striking 5-storey block that combines functionality, sustainability, and modern aesthetics. Key features include 18 EPC A-rated affordable housing units: These homes are designed with energy efficiency in mind, offering residents cost-effective, environmentally friendly living spaces. 4 retail units: Strategically placed at the ground level, these spaces aim to bolster the local economy and provide convenient access to essential services.",
      //         },
      //       ],
      //     },
      //   ],
      //   [
      //     {
      //       headings: [
      //         {
      //           main: true,
      //           text: "Sustainability at the Core",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "The development is engineered to reduce environmental impact and promote sustainable living. Notable green features include: Solar panels, ensuring renewable energy integration and reducing reliance on traditional power sources. Bike stores, encouraging eco-friendly transportation. Energy-efficient amenities, making it easier for residents to live sustainably while saving on energy costs.",
      //         },
      //       ],
      //     },
      //   ],
      //   [
      //     {
      //       headings: [
      //         {
      //           main: true,
      //           text: "Modern Conveniences",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Eagle House is designed with community needs in mind, offering:",
      //         },
      //       ],
      //     },
      //   ],
      //   [
      //     {
      //       headings: [
      //         {
      //           main: true,
      //           text: "Onsite parking, providing convenience for residents and retail customers.",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Secure storage facilities for bicycles, aligning with the push towards greener commuting options.",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Thoughtful planning for accessibility and comfort, ensuring it meets the diverse needs of its residents and visitors.",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Eagle House stands as a beacon of what modern, sustainable housing can achieve, balancing affordability, functionality, and environmental responsibility. As the project nears completion, it is set to redefine community living in the region, showcasing the power of collaboration between innovative developers and forward-thinking housing associations like Tai Tarian.",
      //         },
      //       ],
      //     },
      //     {
      //       text_block: [
      //         {
      //           text: "Stay tuned as Eagle House prepares to welcome its first residents, marking another milestone in the journey toward sustainable and affordable living.",
      //         },
      //       ],
      //     },
      //   ],
      // ],
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Project Details",
              },
            ],
          },
          {
            text_block: [
              {
                text: " Eagle House is a sustainable, mixed-use development at 2 Talbot Road, Port Talbot. It features 18 affordable, energy-efficient apartments on the upper floors and ground-floor office/commercial units. The project is designed to enhance local amenity, support community businesses, and improve the public realm.",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Units:",
                text: "18 plus 4 commercial units",
              },
              {
                header: "Estimated completion:",
                text: "Summer 2025",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Sustainability Features",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Energy Efficiency:",
                text: "The building is designed to achieve an EPC A rating and includes provisions for a PV panel array and solar energy integration.",
              },
              {
                header: "SuDS	and	Drainage:",
                text: "Sustainable Urban Drainage Systems (SuDS) measures—such as bioretention areas, vegetated swales, permeable paving, and a detailed rainfall catchment plan—manage surface water runoff and reduce flood risk.",
              },
              {
                header: "Use of	Recycled Materials:",
                text: "Reclaimed stone is used in constructing a new stone wall along Oakwood Lane, promoting resource efficiency and local sourcing.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Construction	&	Building	Details",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Modern	Build	System:",
                text: "Eagle House is constructed with a timber frame combined with a brick slip system provided by LoCal Homes. This method enhances thermal performance, reduces embodied energy, and supports local industry.",
              },
              {
                header: "Robust	and	Resilient:",
                text: "The multi-storey structure is engineered with advanced layouts and traditional materials that blend durability with modern design, ensuring comfortable, affordable living.",
              },
              {
                header: "Integrated	Engineering:",
                text: "Detailed engineering layouts and construction phase plans guarantee high standards of safety and quality throughout the build process.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Community Benefits & S106	Agreement",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Community Investment:",
                text: "Under the Section 106 agreement, Eagle House includes improvements to local infrastructure such as enhanced footways, secure bicycle storage, and designated areas for Electric Vehicle Charging, benefitting residents and visitors alike.",
              },
              {
                header: "Local Economic Boost:",
                text: "Ground-floor commercial units are designed to support local businesses, contributing to economic growth in the area.",
              },
              {
                header: "Enhanced	Public Realm:",
                text: "The project brings upgraded public spaces and landscaping, fostering a stronger sense of community and improved urban living conditions.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Landscaping	&	Drainage",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Attractive,	Functional	Design:",
                text: "A comprehensive landscaping scheme includes both hardscape and softscape elements that protect and enhance the visual amenity of the area.",
              },
              {
                header: "Water	Management:",
                text: " Integrated drainage systems—including bio-retention areas and permeable paving—are designed to manage stormwater effectively, ensuring long-term sustainability and reducing surface water pollution.",
              },
              {
                header: " Public	Access:",
                text: "New footways and tactile paving are introduced to improve pedestrian safety and accessibility.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: " Site	Remediation	&	Environmental	Management",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Remediation	Strategy:",
                text: "A detailed remediation plan has been implemented based on extensive site investigations. This ensures that any identified soil contamination is capped and managed, making the site safe for its intended use.",
              },
              {
                header: "Environmental	Oversight:",
                text: "Comprehensive environmental and construction management plans guide the development, ensuring minimal disruption to local ecosystems and compliance with health and safety regulations.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Planning	&	Regulatory	Compliance",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: " Full	Planning	Permission:",
                text: "The project has been granted planning permission by Neath Port Talbot County Borough Council, with all approved plans and conditions—such as those relating to construction, historic recording, and waste management—ensuring regulatory compliance.",
              },
              {
                header: "Pre-Commencement	Conditions:",
                text: "Requirements—from notifying the local authority and displaying a site notice to securing detailed assessments for contamination, demolition, and traffic management—are in place to safeguard both the project and the local community.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      history_of_eagle_house: {
        id: `history_of_eagle_house`,
        no: 1,
        active: true,
        title: `History of Eagle House`,
        date: `2024-12-13`,
        overview: `Eagle House, a landmark in Port Talbot, carries a fascinating story of transformation and resilience, intertwining with the town's rich history. Known by various names over the... `,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "The Rich History of Eagle House: A Testament to Port Talbot's Evolution",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Eagle House, a landmark in Port Talbot, carries a fascinating story of transformation and resilience, intertwining with the town's rich history. Known by various names over the decades—from the Eagle Inn to the Port Talbot Temperance Hotel and finally Eagle House—it is the oldest commercial building in Port Talbot, bearing witness to the area's development from the mid-19th century to the present day.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Beginnings with Rees Roderick",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The story of Eagle House begins in the 1850s with Rees Roderick, a local stonemason and builder employed by the Margam Estate. In 1857, he leased a piece of land near the newly built Port Talbot Station, a relatively isolated area at the time. Over the next decade, Roderick expanded his operations and built a home for his family on the site, which eventually became the Eagle Inn in 1874.As the first significant building near the station, the Eagle Inn served a growing number of railway passengers. Initially a modest beer house, it later gained a spirit license in 1879 after Roderick made substantial improvements, including stables and additional facilities.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Changing Identity",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "After Roderick’s death in 1881, the Margam Estate took over the property, leasing it to various proprietors who renamed it the Eagle Hotel. By the late 19th century, the hotel became a hub for local events and gatherings, even hosting skittles tournaments and community meetings.A major shift occurred in 1890 when Emily Talbot, inheritor of the Margam Estate, transformed the building into a Temperance Hotel. Reflecting her support for the temperance movement, the Port Talbot Hotel and Coffee Tavern offered non-alcoholic refreshments and accommodations, emphasizing social reform over profit.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Witness to Change and Adversity",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The 20th century brought new challenges and opportunities for Eagle House. During World War II, a German bomb struck the building, leading to its temporary vacancy. In 1941, it became the headquarters of the 499 Squadron Air Training Corps, with notable figures such as playwright P.H. Burton and a young Richard Burton associated with its activities.",
                },
              ],
            },
          ],
        ],
      },
    },
    faq: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Who	is	developing	Eagle	House?",
              },
            ],
          },
          {
            text_block: [
              {
                text: " The project is being developed by Castell Group in partnership with Tai Tarian.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: " What	is	the	project	timeline?",
              },
            ],
          },
          {
            text_block: [
              {
                text: " Residents may ask when construction is scheduled to begin, when it’s expected to be completed, and when the units will become occupied.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "What	sustainable	features	does	the	development	include?",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This covers energy efficiency (PV panels, EPC A rating), sustainable urban drainage (SuDS, bioretention areas, permeable paving), and the use of locally sourced and reclaimed materials.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: " What	building	methods	and	materials	are	being	used?",
              },
            ],
          },
          {
            text_block: [
              {
                text: " The construction uses a modern timber frame with a brick slip system, chosen for its thermal performance, durability, and local sourcing benefits.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "How	will	Eagle	House	benefit	the	local	community?",
              },
            ],
          },
          {
            text_block: [
              {
                text: "FAQs might cover Section 106 contributions, improvements to local  infrastructure (footways, cycle storage, EV charging), and economic benefits  from the commercial units.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: " Will	there	be	any	impact	on	traffic,	parking,	or	public	spaces?",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Questions about construction-related disruptions, changes to parking arrangements, traffic management plans, and enhancements to public access or landscaping are common.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "What	measures	are	in	place	for	environmental	protection	and	flood risk	management?",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Residents will want to know about SuDS measures, flood prevention features, and how the project manages stormwater and drainage to protect local water quality.",
              },
            ],
          },
        ],
      ],
    },
  },
  c010: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "New Homes and Revitalized Spaces: The Samlet Road Development Project",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The Samlet Road area in Llansamlet, Swansea, is undergoing a remarkable transformation with the Castell Group leading the charge to create 35 new homes. This exciting project, in collaboration with Caredig, is set to provide a mix of high-quality housing on a previously underutilized brownfield site adjacent to Aldi on Peniel Green Road.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A Blend of Modern Living",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development will comprise 17 houses and 18 apartments, blending a mix of:",
              },
            ],
          },
          {
            bullet_points: [
              {
                text: "1 and 2-bedroom apartments",
              },
              {
                text: "2 and 3-bedroom houses",
              },
            ],
          },
          {
            text_block: [
              {
                text: "These homes aim to cater to diverse family sizes and modern lifestyles, while the associated green spaces and amenities enhance the livability of the area.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Sustainability and Biodiversity in Focus",
              },
            ],
          },
          {
            text_block: [
              {
                text: "In alignment with sustainable urban development principles, a Biodiversity Enhancement Scheme has been implemented. Key measures include:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Habitat Preservation:",
                text: "The site includes provisions for nesting birds, bats, and hedgehogs, with carefully designed artificial shelters and roosts.",
              },
              {
                header: "Green Infrastructure:",
                text: "New green spaces will feature native wildflower planting and carefully curated landscaping, promoting pollinator-friendly environments.",
              },
              {
                header: "Invasive Species Management:",
                text: "Steps are being taken to remove non-native invasive species such as Buddleia and Cotoneaster, replacing them with native vegetation.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A Thoughtful Layout",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The proposed layout incorporates:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Natural Play Areas:",
                text: "Outdoor seating and play spaces are integrated into green zones to encourage community interaction.",
              },
              {
                header: "Efficient Drainage Solutions:",
                text: "Innovative drainage systems are designed to ensure sustainable water management.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Addressing Local Needs",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This development is a thoughtful response to housing demand in the region. By revitalizing a brownfield site, the project avoids encroaching on greenfield land, preserving Swansea’s natural beauty while meeting community needs.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Future Prospects",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The project is not just about building homes; it's about creating a vibrant community hub where people can thrive. With its modern design, environmental stewardship, and strategic location, the Samlet Road development promises to become a cornerstone of sustainable living in Llansamlet.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      milestone_in_affordable_housing: {
        id: `milestone_in_affordable_housing`,
        no: 1,
        active: true,
        title: `A Milestone in Affordable Housing`,
        date: `2024-12-13`,
        overview: `Castell Group is proud to announce the progress of our Samlet Road development, an ambitious project to deliver 35 high-quality, affordable homes in Swansea. Situated on a brownfield...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Milestone in Affordable Housing",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Castell Group is proud to announce the progress of our Samlet Road development, an ambitious project to deliver 35 high-quality, affordable homes in Swansea. Situated on a brownfield site adjacent to Aldi, the development breathes new life into a space that once housed an empty car dealership.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This project marks a significant milestone as our first development in Wales and our third partnership with the Registered Social Landlord, Caredig. Together, we are dedicated to creating modern, sustainable housing that meets the needs of the community and provides secure, affordable homes for those who need them most.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Diverse Housing Mix",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Samlet Road scheme features a thoughtful mix of homes to cater to a variety of needs, including:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  text: "1- and 2-bedroom apartments",
                },
                {
                  text: "2- and 3-bedroom houses",
                },
                {
                  text: "Private and shared amenity spaces for all residents",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Each property is designed and built to a high standard, ensuring a comfortable and stylish living environment for tenants.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Sustainability at the Core",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Sustainability is a cornerstone of the Samlet Road project. Each home is equipped with features designed to reduce energy consumption and costs for tenants, including:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  text: "Advanced insulation and airtight construction",
                },
                {
                  text: "Energy-efficient heating systems",
                },
                {
                  text: "Renewable energy solutions such as solar panels",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "These features not only help the environment but also make everyday living more affordable for residents.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Recognized Excellence",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We’re thrilled that the Samlet Road project has been shortlisted for the Project of the Year Award at the Constructing Excellence in Wales (CEW) awards. This recognition underscores the hard work and dedication of the Castell Group team, our partnership with Caredig, and the innovative approach taken to deliver this exceptional development.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Vision for the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As construction progresses, the site is becoming unrecognizable from its former state. What was once a disused car dealership is now on its way to becoming a thriving community. We’re proud to see our vision come to life and to contribute to the growing demand for affordable housing in Wales.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This project reflects Castell Group’s commitment to creating sustainable, high-quality housing that positively impacts local communities. As we near completion, we look forward to welcoming tenants into their new homes and seeing the vibrant community at Samlet Road flourish.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Stay tuned for updates on this transformative project as we continue to make a difference in Swansea and beyond!",
                },
              ],
            },
          ],
        ],
      },
      shortlist_for_presitigious_housing_award: {
        id: `shortlist_for_presitigious_housing_award`,
        no: 2,
        active: true,
        title: `Samlet Road Development Shortlisted for Prestigious Housing Award`,
        date: `2024-12-13`,
        overview: `We are thrilled to share that our Samlet Road development in Swansea has been nominated for Best Build to Rent Development at the highly esteemed Inside Housing Development...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Samlet Road Development Shortlisted for Prestigious Housing Award",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We are thrilled to share that our Samlet Road development in Swansea has been nominated for Best Build to Rent Development at the highly esteemed Inside Housing Development Awards. This accolade underscores our unwavering commitment to delivering innovative, sustainable housing solutions in collaboration with LoCaL Homes and housing association Caredig.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Landmark Development in Swansea",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Samlet Road is a transformative project breathing new life into the former site of a car dealership. The development is delivering 35 high-quality, EPC-A rated social homes, offering a mix of:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  text: "One and two-bedroom apartments",
                },
                {
                  text: "Two and three-bedroom houses",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Designed to meet diverse housing needs, each home has been meticulously crafted to the highest standards, featuring sustainable technologies that reduce energy costs for tenants while promoting environmental responsibility.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Innovation Meets Sustainability",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The development has been brought to life using LoCaL Homes’ ECO150 insulated timber frames, manufactured in their award-winning factory. This cutting-edge construction technique has enabled:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  text: "Streamlined construction to ensure timely delivery.",
                },
                {
                  text: "Minimized environmental impact by reducing waste and emissions.",
                },
                {
                  text: "Energy efficiency for future residents, lowering fuel bills and carbon footprints.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Mike Doolan, Sales and Partnerships Manager at LoCaL Homes, highlighted the significance of the project:",
                },
              ],
            },
            {
              text_block: [
                {
                  text: `“This project stands out as a prime example of innovative construction and sustainable development. By utilizing our ECO150 insulated timber frames, we are helping to streamline the construction process and minimize environmental impact. Future occupants of the new homes will feel the benefit of reduced fuel bills."`,
                  italic: true,
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Recognition of Excellence",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The nomination for Best Build to Rent Development is a testament to the collaborative efforts of Castell Group, LoCaL Homes, and Caredig in setting a new benchmark for sustainable, high-quality housing.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Award Announcement",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The winners of the Inside Housing Development Awards will be revealed on Friday, 27 September 2024, at the Royal Lancaster in London. We’re incredibly proud of this recognition and eagerly await the results alongside our peers in the industry.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we remain dedicated to creating homes and communities that inspire. This nomination is not just a milestone for Samlet Road but a celebration of what’s possible when innovation, sustainability, and community focus come together.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Stay tuned for updates as we approach the awards ceremony!",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c015: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "About the Project",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development comprises 14 one-bedroom apartments designed to meet the highest standards of energy efficiency. With an EPC A rating, these homes will not only help future residents reduce their energy consumption but also lower their overall living costs. Castell Group's commitment to sustainability and quality is evident throughout the project, which aligns seamlessly with the Welsh Government’s goals of increasing affordable housing stock and promoting greener construction practices.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Planning and Approvals",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The transformation of the Fruit Warehouse site involved navigating a comprehensive planning process to ensure compliance with local and national guidelines. From securing gigabit-capable broadband infrastructure to devising detailed drainage strategies and environmental management plans, the Castell Group has worked closely with planning authorities to meet all conditions associated with the planning permission. W.R. Bishop & Co., the previous occupants of the site, have supported the transition to this new phase of development, reflecting the growing need for adaptable spaces that serve community needs.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A Model for Sustainable Housing",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This project stands out not only for its energy-efficient design but also for its focus on creating accessible, affordable housing for the local community. By leveraging grant funding and adhering to rigorous planning standards, the development exemplifies a collaborative approach to addressing housing challenges in Wales. With the construction now in full swing, the Fruit Warehouse development is poised to become a benchmark for similar projects in the region, showcasing how innovation and adherence to planning standards can come together to create meaningful change.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      transforming_communities: {
        id: `transforming_communities`,
        no: 1,
        active: true,
        title: `Transforming Communities – Castell Group’s Fruit Warehouse Development`,
        date: `2024-12-13`,
        overview: `At Castell Group, we pride ourselves on delivering transformative projects that serve local communities while setting new benchmarks in sustainable housing. One such project...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Transforming Communities – Castell Group’s Fruit Warehouse Development",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we pride ourselves on delivering transformative projects that serve local communities while setting new benchmarks in sustainable housing. One such project currently underway is the redevelopment of the Fruit Warehouse site in Tonyrefail, a venture that combines heritage, innovation, and a commitment to meeting modern housing needs.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "From Warehouse to Homes",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The site, previously home to W.R. Bishop & Co., a renowned supplier of fresh and prepared fruit, vegetables, and dairy products, has been a cornerstone of the local business community for over 60 years. With operations that supported the NHS, local authorities, and independent caterers, the Fruit Warehouse was a hub of activity, supplying fresh produce across South Wales. Now, as the needs of the region evolve, so does the purpose of this iconic site. Castell Group, in collaboration with Newydd Housing Association, is leading the redevelopment to create 14 energy-efficient, EPC A-rated one-bedroom apartments. These homes are 100% affordable and supported by a Welsh Government grant, reflecting a shared vision for inclusive and sustainable living.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Design Rooted in Community Needs",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The project represents a thoughtful redesign and resubmission following community feedback. Key changes include:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Reduction in Scale:",
                  text: "The size of the new building has been reduced, along with the total number of units, ensuring a more harmonious integration into the neighborhood.",
                },
                {
                  header: "Improved Positioning:",
                  text: "The building is now sited further from the nearest residential properties, addressing concerns raised by the local community.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "These adjustments highlight Castell Group’s dedication to engaging with stakeholders and ensuring the development respects its surroundings.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building for the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The new development is more than just a residential scheme—it’s a step toward a more sustainable future. By providing affordable, energy-efficient homes, the project supports the Welsh Government’s housing and environmental goals. It also helps address the pressing demand for high-quality, cost-effective housing in Rhondda Cynon Taf.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Preserving a Legacy",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Though the Fruit Warehouse will soon make way for modern housing, the legacy of W.R. Bishop & Co. lives on. The family-run business, known for its commitment to quality and community, remains a vital part of the local economy. This redevelopment is not an end but a continuation of the site’s tradition of serving the community, albeit in a new and impactful way. As construction progresses, Castell Group is proud to contribute to a project that not only transforms a physical space but also enriches the lives of its future residents and the surrounding community.",
                },
              ],
            },
          ],
        ],
      },
      sustainability_and_innovation: {
        id: `sustainability_and_innovation`,
        no: 2,
        active: false,
        title: `Sustainability and Innovation at the Heart of the Fruit Warehouse Development`,
        date: `2024-12-13`,
        overview: `As the Fruit Warehouse redevelopment progresses, sustainability and innovation stand out as central pillars of this transformative project. Castell Group is committed to creating...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Sustainability and Innovation at the Heart of the Fruit Warehouse Development",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As the Fruit Warehouse redevelopment progresses, sustainability and innovation stand out as central pillars of this transformative project. Castell Group is committed to creating homes that not only address today’s housing needs but also set new standards for energy efficiency, modern living, and environmental responsibility.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "EPC A-Rated Homes: Energy Efficiency and Comfort",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "One of the most exciting aspects of the Fruit Warehouse project is the creation of EPC A-rated homes. This designation, the highest energy efficiency rating possible, comes with numerous benefits for future residents:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Lower Energy Bills:",
                  text: "By minimizing energy consumption through advanced building techniques and efficient systems, residents will enjoy significantly reduced utility costs.",
                },
                {
                  header: "Reduced Carbon Emissions:",
                  text: "With energy-efficient heating and insulation, these homes contribute to a smaller carbon footprint, aligning with global efforts to combat climate change.",
                },
                {
                  header: "Enhanced Comfort:",
                  text: "Superior insulation and ventilation create a consistently comfortable indoor environment, keeping homes warm in winter and cool in summer without excessive energy use.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Materials and Design: Building for the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we believe that sustainable materials and innovative design are critical to constructing homes that stand the test of time. The Fruit Warehouse development incorporates:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Sustainable Materials:",
                  text: "Recycled or low-impact building materials are used wherever possible, reducing waste and conserving natural resources.",
                },
                {
                  header: "Energy-Efficient Systems:",
                  text: "From state-of-the-art heating systems to LED lighting, every detail is optimized to reduce energy usage.",
                },
                {
                  header: "Advanced Insulation:",
                  text: "High-performance insulation minimizes heat loss, enhancing both energy efficiency and comfort.",
                },
                {
                  header: "Modern Ventilation:",
                  text: "Systems are designed to improve air quality while maintaining energy efficiency, ensuring a healthier living environment for residents.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Modern Living Standards: Designed for the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "In addition to its sustainable design, the Fruit Warehouse development embraces modern conveniences and amenities that align with the evolving needs of residents:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Gigabit-Capable Broadband:",
                  text: "Every home will be equipped with gigabit-capable broadband infrastructure, ensuring seamless connectivity for work, education, and entertainment.",
                },
                {
                  header: "Eco-Friendly Landscaping:",
                  text: "Thoughtfully designed green spaces prioritize biodiversity, water conservation, and natural beauty, creating a harmonious environment for residents.",
                },
                {
                  header: "Low Carbon Footprint:",
                  text: "The entire development process, from design to construction, focuses on reducing carbon emissions and supporting a greener future.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Blueprint for Sustainable Living",
                },
              ],
            },

            {
              text_block: [
                {
                  text: "The Fruit Warehouse project exemplifies how sustainability and innovation can work hand in hand to create homes that are not only environmentally responsible but also practical, comfortable, and future-ready. By prioritizing energy efficiency, sustainable materials, and modern living standards, Castell Group is proud to deliver a development that reflects our commitment to both the planet and the community.",
                },
              ],
            },
          ],
        ],
      },
      community: {
        id: `community`,
        no: 3,
        active: false,
        title: `Community-Focused Living at the Fruit Warehouse Development`,
        date: `2024-12-13`,
        overview: `The Fruit Warehouse redevelopment in Tonyrefail is more than just a housing project—it’s a vision for vibrant, community-focused living. Castell Group is dedicated to creating...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Community-Focused Living at the Fruit Warehouse Development",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Fruit Warehouse redevelopment in Tonyrefail is more than just a housing project—it’s a vision for vibrant, community-focused living. Castell Group is dedicated to creating spaces that enhance residents’ quality of life while integrating seamlessly into the local fabric. Here’s a closer look at the community features and design principles shaping this transformative project.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Amenities for Residents: Designed with Everyday Living in Mind",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At the heart of the Fruit Warehouse development is a commitment to meeting the diverse needs of its future residents. Thoughtfully planned amenities ensure convenience, accessibility, and sustainability:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Communal Spaces:",
                  text: "Shared outdoor areas provide a place for residents to connect, relax, and enjoy the natural surroundings. Whether it’s a green courtyard or landscaped gardens, these spaces promote community interaction and well-being.",
                },
                {
                  header: "Cycle Storage:",
                  text: "With a growing emphasis on sustainable transportation, secure cycle storage facilities encourage residents to adopt greener travel habits while reducing reliance on cars.",
                },
                {
                  header: "Accessibility Features:",
                  text: "Designed to accommodate residents of all abilities, the apartments and communal areas incorporate features like step-free access, wide doorways, and elevator systems. These considerations ensure inclusivity and ease of use for everyone.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Welsh Design Principles: A Development Rooted in Tradition",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Respecting and celebrating local culture is a cornerstone of the Fruit Warehouse project. Castell Group has drawn inspiration from traditional Welsh architecture and design to create a development that feels like a natural extension of the area:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Local Aesthetic:",
                  text: "The building’s exterior is designed to complement the surrounding environment, using materials and colors that reflect the character of Tonyrefail and its heritage.",
                },
                {
                  header: "Cultural Sensitivity:",
                  text: "By aligning with regional planning guidelines and community preferences, the project honors the traditions and identity of the Rhondda Cynon Taf area.",
                },
                {
                  header: "Harmony with Nature:",
                  text: "Eco-friendly landscaping not only enhances the site’s visual appeal but also respects the area’s natural ecosystems, contributing to biodiversity and sustainability.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Community-Oriented Vision",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Fruit Warehouse redevelopment exemplifies how thoughtful design and planning can create a space that serves more than just functional needs. By prioritizing communal amenities and integrating Welsh design principles, Castell Group is building more than homes—it’s creating a place where residents can thrive as part of a connected and inclusive community.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This project is a testament to the power of blending modern living with cultural heritage, ensuring that new developments enrich the local landscape while providing residents with a place they’re proud to call home.",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c017: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Rhondda Bowl: Transforming Tonyrefail With Affordable, Sustainable Homes",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The Rhondda Bowl site in Tonyrefail is undergoing an exciting transformation, led by Castell Group, to create a modern, sustainable community of affordable homes. This redevelopment is a collaborative effort with Newydd Housing Association, supported by a Welsh Government grant, aimed at addressing local housing needs while prioritizing sustainability and innovation.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "About the Project",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development will deliver 20 high-quality, EPC A-rated homes, offering residents energy-efficient living with significantly reduced energy costs. The scheme features a carefully planned mix of property types to cater to a variety of needs:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "12 one-bedroom apartments:",
                text: "Ideal for individuals or couples seeking affordable, modern living spaces.",
              },
              {
                header: "3 two-bedroom houses:",
                text: "Perfect for small families or those looking to step onto the housing ladder.",
              },
              {
                header: "5 three-bedroom houses:",
                text: "Designed to accommodate larger families, offering spacious and comfortable homes.",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This blend of housing ensures the community is diverse and inclusive, meeting a range of lifestyle and affordability requirements.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Sustainability at Its Core",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The Rhondda Bowl development has been designed with sustainability in mind, incorporating eco-friendly features such as:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Energy-efficient construction:",
                text: "All homes achieve an EPC A rating, ensuring top-tier energy performance.",
              },
              {
                header: "Rain gardens and sustainable drainage systems:",
                text: "These features help manage surface water runoff while enhancing the site's green spaces.",
              },
              {
                header: "Swift bricks for local wildlife:",
                text: "These specially designed bird boxes support biodiversity by providing habitats for swifts and other native species.",
              },
              {
                header: "EV charging infrastructure:",
                text: "Future-proofing the development for the growing shift to electric vehicles.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Building a Brighter Future",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This project is a shining example of how Castell Group is partnering with housing associations and local authorities to deliver much-needed affordable housing. With funding support from the Welsh Government, the Rhondda Bowl site is not only addressing housing shortages but also contributing to environmental preservation and community enrichment. The redevelopment is well underway, and we are excited to see the positive impact these homes will have on the community. Stay tuned for more updates as we continue to bring this vision to life!",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      from_bowling_alley_to_energy_efficent_homes: {
        id: `from_bowling_alley_to_energy_efficent_homes`,
        no: 1,
        active: true,
        title: `From Bowling Alley to Energy-Efficient Homes: The Transformation of Rhondda Bowl`,
        date: `2024-12-13`,
        overview: `The Rhondda Bowl in Tonyrefail, once a beloved local landmark, has undergone a remarkable transformation. Known for its vibrant atmosphere, the venue offered bowling lanes, arcade games...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "From Bowling Alley to Energy-Efficient Homes: The Transformation of Rhondda Bowl",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Rhondda Bowl in Tonyrefail, once a beloved local landmark, has undergone a remarkable transformation. Known for its vibrant atmosphere, the venue offered bowling lanes, arcade games, a bar, and a diner, serving as a go-to spot for family outings and celebrations. Over time, however, changing leisure trends led to its closure. Before its redevelopment, the site gained a moment of fame in 2021 as a filming location for Netflix’s hit series Sex Education.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Today, the site is embracing a new identity. In a joint project between Castell Group and Newydd Housing Association, the former bowling alley is being transformed into 20 affordable, eco-friendly homes. Approved in 2023, this redevelopment is part of a broader initiative to address local housing needs in Tonyrefail.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The new homes, a mix of one-bedroom apartments and two- and three-bedroom houses, are being constructed with sustainability at their core. Using Eco-150 timber frame technology, the project prioritizes low-carbon methods and non-fossil fuel heating systems, achieving the highest possible Energy Performance Certificate (EPC) rating of A. The initiative is supported in part by a Welsh Government Social Housing Grant and is expected to be completed by summer 2025.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This transformation reflects a broader trend of repurposing community spaces to meet urgent housing demands while setting new standards for sustainable construction.The Rhondda Bowl’s legacy as a cherished gathering place may be gone, but its site will continue to serve the community in a meaningful and forward-looking way.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Stay tuned for updates as the development progresses and prepares to welcome its first residents in 2025. For more information on the housing scheme, visit ",
                },
                {
                  link: "https://castellgroup.co.uk/projects/C017",
                  text: "The Rhondda Bowl Project Page",
                  aria_label: "The Rhondda Bowl Project Page",
                },
                {
                  text: ".",
                },
              ],
            },
          ],
        ],
      },
      from_local_hub_to_netflix_fame: {
        id: `from_local_hub_to_netflix_fame`,
        no: 2,
        active: true,
        title: `From Local Hub to Netflix Fame: The Story of Rhondda Bowl`,
        date: `2024-12-13`,
        overview: `The Rhondda Bowl in Tonyrefail, Rhondda Cynon Taf, has a story that combines nostalgia, entertainment, and transformation. Once a bustling hub for locals, this iconic venue...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "From Local Hub to Netflix Fame: The Story of Rhondda Bowl",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Rhondda Bowl in Tonyrefail, Rhondda Cynon Taf, has a story that combines nostalgia, entertainment, and transformation. Once a bustling hub for locals, this iconic venue is remembered fondly as a place for bowling, arcade games, and family gatherings. However, its story took an unexpected turn when it became a star in its own right, featured in Netflix’s global hit series Sex Education.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Local Treasure",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "For years, the Rhondda Bowl was at the heart of the community, offering fun and entertainment for all ages. Families flocked there for birthday parties, friends gathered for weekend outings, and couples enjoyed casual dates. Its retro charm and lively atmosphere made it a memorable part of growing up in the area.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "But as trends shifted and leisure habits changed, the Rhondda Bowl eventually closed its doors, joining the list of once-thriving local businesses that became casualties of time. For a while, it seemed destined to be remembered only in nostalgia.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Netflix Spotlight",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "That all changed when Sex Education, a show known for its quirky characters and bold storytelling, chose the Rhondda Bowl as one of its filming locations. The show’s use of vibrant, retro-inspired settings made the bowling alley a perfect backdrop, capturing the imagination of audiences worldwide.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "For fans of Sex Education, the Rhondda Bowl became an iconic location, appearing in scenes that perfectly encapsulated the show's blend of humor, awkwardness, and heart. For locals, it was a thrill to see such a familiar spot gaining international attention, sparking a renewed interest in the site and its history.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A New Chapter",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Today, the Rhondda Bowl is entering a new phase of its journey. Following its closure and Netflix fame, the site is being redeveloped into 20 eco-friendly homes as part of a joint project by Castell Group and Newydd Housing Association. These energy-efficient properties aim to address local housing needs while integrating sustainable construction practices, marking a significant shift in the site’s purpose.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "While it may no longer serve as a bowling alley, the Rhondda Bowl’s legacy as a cherished community space continues. Its transformation into modern, sustainable homes reflects how spaces can evolve to meet the needs of the times, all while preserving the fond memories they hold for the community.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Lasting Legacy",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The story of the Rhondda Bowl is a testament to the power of place and memory. From a local favourite to a global star, and now a site of transformation, it remains an emblem of how the past, present, and future can intersect in unexpected ways.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As the site’s redevelopment progresses, the spirit of the Rhondda Bowl lives on—not only in the homes that will soon stand there but also in the hearts of those who remember its original charm and its brief moment in the Netflix spotlight.",
                },
              ],
            },
          ],
        ],
      },
      tonyrefail_transformation: {
        id: `tonyrefail_transformation`,
        no: 3,
        active: true,
        title: `Tonyrefail Transformation: Rhondda Bowl Becomes Social Housing Development`,
        date: `2024-12-13`,
        overview: `The Rhondda Bowl in Tonyrefail, once a lively leisure venue, is undergoing a significant transformation. This iconic building, once home to bowling lanes and a popular community...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Tonyrefail Transformation: Rhondda Bowl Becomes Social Housing Development",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Rhondda Bowl in Tonyrefail, once a lively leisure venue, is undergoing a significant transformation. This iconic building, once home to bowling lanes and a popular community gathering space, is being redeveloped into a 100% social housing development. The project, spearheaded by Newydd Housing Association and Castell Group, with support from Rhondda Cynon Taf Council, will provide 20 new, energy-efficient homes.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The new homes will feature a mix of one-bedroom apartments, two-bedroom houses, and three-bedroom homes, all designed to meet the highest environmental standards. The construction will utilize non-fossil fuel heating systems and aim for an A-rated Energy Performance Certificate (EPC), ensuring the homes are both comfortable and sustainable. With work already underway, the development is expected to be completed by the summer of 2025.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This transformation is part of a broader £5 million investment in the local area. Another nearby site, the former WR Bishop & Co warehouse, will be converted into 14 one-bedroom apartments. Together, these two developments are a vital step in addressing the housing shortage in Tonyrefail and offering affordable homes for social rent, funded partly through the Welsh Government's Social Housing Grant.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Beyond providing new homes, these projects also offer community benefits, including job creation and apprenticeship opportunities. They reflect a broader trend in Wales towards building sustainable, affordable housing while repurposing former commercial spaces to meet modern needs.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Rhondda Bowl's redevelopment also marks the end of an era for a venue that was beloved by many, especially after it gained attention in the popular Netflix series Sex Education. While the bowling alley is now a thing of the past, the new homes will continue to serve the community, addressing housing needs while promoting sustainability.",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c019: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Castell Group’s Exciting Wind Street Development",
              },
            ],
          },
          {
            text_block: [
              {
                text: "At Castell Group, we believe in transforming spaces and enriching communities. Our Wind Street project in Neath embodies this vision by turning a piece of the town’s history into a beacon of modern living. We are proud to announce our redevelopment of the former Working Men’s Club into a vibrant new residential complex, in partnership with Pobl. Once completed, this space will host 38 stylish, energy-efficient apartments—a mix of one- and two-bedroom homes designed with comfort and sustainability in mind.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A New Era for Neath Living",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development is more than just a construction project; it’s about creating opportunities for better living. By replacing the aging structure with a thoughtfully designed apartment block, we’re making space for families, professionals, and individuals to thrive in the heart of Neath. Residents will enjoy modern amenities, communal spaces, and secure facilities, all within a location that’s well-connected to the town’s vibrant cultural and social life. Whether it’s the young professional looking for their first home or an older resident seeking a low-maintenance, energy-efficient space, Wind Street will cater to a diverse range of lifestyles.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Built for the Future",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Our approach to this project is rooted in sustainability. The new apartments will be constructed using advanced building techniques and materials to enhance energy efficiency and reduce environmental impact. By implementing features like improved insulation and modern design standards, residents can enjoy lower energy bills while reducing their carbon footprint.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Why This Matters",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Neath is a town with a rich history, and the redevelopment of Wind Street reflects its bright future. Projects like this don’t just provide homes; they inspire change in the way people live and interact with their environment. Modern, accessible housing can rejuvenate communities, attract new residents, and contribute to the local economy. Moreover, this development reflects our commitment to working hand-in-hand with the community. Partnering with Pobl ensures that these homes are built to meet real needs, creating housing solutions that are affordable, functional, and reflective of Neath’s character.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Looking Ahead",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Wind Street is just one of many projects where Castell Group is striving to make a difference. It represents our belief that good housing is the foundation of strong communities. As we bring this exciting project to life, we’re eager to see how these new apartments will positively impact the lives of Neath residents, offering them not just a place to live but a home where they can build their future. Together, let’s celebrate this transformation—a step forward for Neath and a testament to what thoughtful, community-driven development can achieve.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      sustainable_living_in_neath: {
        id: `sustainable_living_in_neath`,
        no: 1,
        active: true,
        title: `Sustainable Living in Neath: Inside the Wind Street Apartments`,
        date: `2024-12-13`,
        overview: `At Castell Group, we’re proud to be at the forefront of sustainability in housing. Our latest development, the Wind Street Apartments in Neath, represents a significant step...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Sustainable Living in Neath: Inside the Wind Street Apartments",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we’re proud to be at the forefront of sustainability in housing. Our latest development, the Wind Street Apartments in Neath, represents a significant step forward in creating homes that are not only stylish and comfortable but also environmentally responsible. In collaboration with Pobl, this project is more than just a redevelopment; it’s a vision for a more sustainable future in Neath.Here’s a closer look at the energy-efficient and sustainable features of the Wind Street Apartments, and how they benefit both residents and the environment.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Energy-Efficient Design for Modern Living",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Wind Street Apartments have been designed with sustainability at the heart of every detail. Through careful planning and the use of advanced building techniques, we’ve created homes that are energy-efficient and cost-effective. Key features include:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Air Source Heat Pumps:",
                  text: "These provide efficient heating and hot water, reducing energy consumption and carbon emissions.",
                },
                {
                  header: "Enhanced Insulation:",
                  text: "High-quality insulation ensures that homes stay warm in the winter and cool in the summer, reducing the need for heating and cooling and helping residents save on energy bills.",
                },
                {
                  header: "High-Performance Windows and Doors:",
                  text: "These ensure maximum thermal efficiency by reducing heat loss and minimizing drafts.",
                },
                {
                  header: "LED Lighting:",
                  text: "Energy-efficient LED lighting throughout the apartments means residents can enjoy long-lasting and eco-friendly illumination, further reducing energy consumption.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "These energy-efficient features are designed to lower the carbon footprint of the development while offering practical savings on utilities for the residents.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Sustainability at the Core",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Sustainability isn’t just about reducing energy use—it’s about being mindful of the materials and systems we put into place. For the Wind Street Apartments, we’ve chosen eco-friendly solutions that prioritize both longevity and minimal environmental impact:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Eco-Friendly Materials:",
                  text: "From building materials with lower embodied carbon to finishes that require less maintenance, every aspect of the development reflects our commitment to sustainability.",
                },
                {
                  header: "Water-Saving Features:",
                  text: "Thoughtfully chosen fixtures and fittings reduce water consumption, making the apartments not only energy-efficient but also water-conscious.",
                },
                {
                  header: "Smart Energy Solutions:",
                  text: "The infrastructure of the apartments is designed to accommodate future renewable energy solutions, such as solar panels and electric vehicle charging stations, helping residents continue their sustainable lifestyles.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "These apartments are an investment in the future of housing, designed to stand the test of time both in terms of durability and environmental responsibility.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Why Sustainability Matters",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we believe that sustainable living isn’t just about benefiting the environment—it’s also about creating better living conditions for our communities. The Wind Street Apartments provide multiple benefits, not only for the residents but for Neath and the wider region:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Lower Carbon Emissions:",
                  text: "By incorporating energy-efficient systems and eco-friendly materials, these apartments help reduce overall carbon emissions, contributing to Wales' environmental goals.",
                },
                {
                  header: "Improved Air Quality:",
                  text: "A focus on sustainable practices reduces reliance on fossil fuels, leading to cleaner air and a healthier living environment.",
                },
                {
                  header: "Stronger Communities:",
                  text: "Sustainable housing doesn’t just improve the environment—it strengthens the community. Residents of these energy-efficient apartments can take pride in knowing they are part of a movement toward more resilient, eco-conscious living.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "By creating sustainable housing solutions, Castell Group is helping to create neighborhoods where people can live, grow, and thrive—while minimizing their impact on the planet.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Castell Group’s Vision for Sustainable Communities",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Sustainability is at the core of everything we do at Castell Group. From our construction methods to our long-term vision for the communities we build, we are committed to reducing our environmental impact while creating homes that meet the needs of today’s residents. The Wind Street Apartments are just one example of how we’re bringing this vision to life—by integrating energy-efficient solutions, sustainable materials, and smart technologies into our developments. This project is more than just a place to live—it’s part of a larger movement to create greener, more sustainable communities. By investing in eco-friendly features and prioritizing the well-being of residents, we’re helping to shape a brighter future for Neath.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Looking Ahead",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As we near the completion of the Wind Street Apartments, we’re excited to see how these energy-efficient homes will contribute to the local community. Not only do they provide much-needed housing, but they also offer a sustainable way of living that benefits both residents and the environment.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we’re committed to building communities that are strong, resilient, and sustainable—one project at a time. The Wind Street development is just the beginning of our ongoing journey to transform how we think about housing.",
                },
              ],
            },
          ],
        ],
      },
      modern_housing_key_to_neaths_growth: {
        id: `modern_housing_key_to_neaths_growth`,
        no: 2,
        active: false,
        title: `Why Modern Housing is Key to Neath’s Growth`,
        date: `2024-12-13`,
        overview: `At Castell Group, we believe that the foundations of a thriving community lie in the homes people live in. Our Wind Street Apartments development in Neath is more than just...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Why Modern Housing is Key to Neath’s Growth",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we believe that the foundations of a thriving community lie in the homes people live in. Our Wind Street Apartments development in Neath is more than just a residential project—it’s a catalyst for local economic growth and a key part of the town’s future. By providing modern, sustainable homes, we’re helping to shape a Neath that’s more vibrant, diverse, and prosperous for generations to come.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "In this post, we explore how modern housing projects like Wind Street can contribute to the local economy, attract new residents, and boost local businesses.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Supporting Local Economic Growth",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The redevelopment of the Wind Street site is a significant step in Neath’s ongoing regeneration. By transforming an underutilized building into a modern housing complex, we’re not only providing high-quality homes but also creating a ripple effect that benefits the entire town. Here’s how:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Job Creation:",
                  text: "Large-scale construction projects like Wind Street provide numerous employment opportunities, both during the build and in the long term. Local contractors, suppliers, and tradespeople are employed, helping to stimulate the regional economy.",
                },
                {
                  header: "Increased Demand for Services:",
                  text: "New residents bring with them an increased demand for services, from retail outlets to healthcare and education. This boost in demand can help drive investment in local businesses and improve infrastructure.",
                },
                {
                  header: "Incentivizing Future Investments:",
                  text: "The success of one development often paves the way for others. As more modern housing projects like Wind Street come to fruition, they signal to potential investors that Neath is a forward-thinking, growth-oriented town.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Attracting New Residents to Neath",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "One of the key drivers of economic growth is the ability to attract new people to an area. With a growing population, the demand for goods, services, and employment opportunities rises, contributing to the vitality of the local economy. The Wind Street Apartments are strategically designed to appeal to a wide range of potential residents, helping Neath become an attractive destination for:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Young Professionals:",
                  text: "Modern, energy-efficient apartments in a central location are ideal for young professionals looking for affordable, well-connected homes. With convenient access to local amenities and transportation links, Wind Street offers an attractive option for those wanting to live and work in Neath.",
                },
                {
                  header: "Families:",
                  text: "The development’s mix of one- and two-bedroom apartments offers affordable housing options for families, creating a diverse and sustainable community. As more families move into the area, local schools, parks, and services will benefit from increased investment and activity.",
                },
                {
                  header: "Seniors:",
                  text: "For older residents looking to downsize, the low-maintenance, energy-efficient design of the Wind Street Apartments provides the perfect living solution, allowing them to live comfortably in a modern environment without compromising on convenience or accessibility.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "By catering to a variety of residents, the Wind Street development ensures that Neath can attract people from all walks of life, making it a more dynamic and vibrant town.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Boosting Local Businesses",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As new residents move into modern housing developments like Wind Street, they bring with them new spending power that can revitalize local businesses and attract new ones. Here’s how:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Increased Foot Traffic:",
                  text: "More people living in and around the town center means more potential customers for local shops, cafes, restaurants, and other businesses. Increased foot traffic can drive up sales and boost the local economy.",
                },
                {
                  header: "Supporting Local Services:",
                  text: "With new residents comes a demand for a range of services—everything from groceries and restaurants to gyms, childcare, and healthcare. As businesses expand to meet these needs, they create jobs and contribute to the growth of the local economy.",
                },
                {
                  header: "Attracting New Businesses:",
                  text: "As Neath becomes a more desirable place to live, it can attract new businesses, both small and large. Retailers, tech startups, and hospitality companies may look to set up shop in the area, further diversifying the local economy and creating more opportunities for residents.",
                },
              ],
            },

            {
              text_block: [
                {
                  text: "The Wind Street Apartments will play a crucial role in supporting Neath’s existing businesses while fostering a thriving marketplace for new enterprises.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Stronger, More Resilient Neath",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, our vision for Neath extends beyond just providing housing—we want to help build a stronger, more sustainable town where businesses, residents, and services can flourish together. The Wind Street Apartments are designed to be part of the larger picture, contributing to Neath’s long-term growth and prosperity.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "By providing high-quality, energy-efficient homes, we’re attracting new residents and supporting local businesses, helping to create a Neath that is both economically vibrant and socially inclusive. As the Wind Street development takes shape, we’re excited to see how it will positively impact the town, inspiring further investment and growth for years to come.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Looking to the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Wind Street Apartments are just the beginning. As more projects like this come to life, Neath will continue to grow and evolve, becoming an even more attractive place to live, work, and invest. Castell Group is proud to be part of that journey, creating homes that help fuel the future of Neath’s economy and community. Want to learn more about the Wind Street development or how modern housing can contribute to local growth? Get in touch with us today or visit our website for more information.",
                },
              ],
            },
          ],
        ],
      },
      for_the_workers: {
        id: `for_the_workers`,
        no: 3,
        active: true,
        title: `From Workers’ Club to Modern Living: The Transformation of Neath’s Historic Landmark`,
        date: `2024-12-13`,
        overview: `Neath, a town rich in history, is known for its vibrant communities and the industrial past that shaped much of its identity. One iconic piece of that history is the former Neath Workers’ Club...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "From Workers’ Club to Modern Living: The Transformation of Neath’s Historic Landmark",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Neath, a town rich in history, is known for its vibrant communities and the industrial past that shaped much of its identity. One iconic piece of that history is the former Neath Workers’ Club, a site that once served as a social and cultural hub for the town’s working-class residents. However, times have changed, and this historic building is now being transformed into something entirely new—a modern residential development, the Wind Street Apartments. In this post, we’ll take a look back at what the Workers’ Club used to be and explore how it’s evolving into a space that meets the needs of Neath’s future.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "The Workers’ Club: A Hub for Neath’s Industrial Community",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Founded in the early 20th century, Neath’s Workers’ Club was established to provide a gathering place for local laborers, miners, and their families. During the industrial boom, this type of social club was essential to the community. It served as a place for working men to relax, share stories, and forge connections after long days of hard work in the mines, factories, and mills that were the backbone of the town’s economy.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Workers’ Club was more than just a bar—it was a social institution. It hosted regular meetings, celebrations, dances, and events that allowed the people of Neath to come together, form bonds, and support one another in a rapidly changing world. The club was often the beating heart of the community, providing a space for local activism, discussions about workers' rights, and even political gatherings. As Neath’s industrial landscape began to change over the decades, the role of the Workers’ Club gradually diminished. The decline of heavy industry in the region, coupled with changing social trends, meant that the club, like many others across the UK, faced a decline in relevance and use. The building, once bustling with activity, fell into disuse as its original purpose faded away.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "The Transformation: A New Era for the Workers’ Club",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Fast forward to the present day, and the former Neath Workers’ Club is undergoing a major transformation. Castell Group, in partnership with Pobl, is redeveloping this historic building into a modern residential complex—38 stylish, energy-efficient apartments. The Wind Street Apartments will include a mix of one- and two-bedroom homes designed with both comfort and sustainability in mind. While the Workers’ Club served as a symbol of Neath’s industrial past, the redevelopment of this site represents a forward-looking vision for the future of the town. Here’s how this transformation will breathe new life into the building and the community:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Modern Housing for a Growing Community:",
                  text: "The Wind Street Apartments will provide high-quality, affordable housing for a range of residents—young professionals, families, and seniors. By converting this historic site into modern apartments, we’re helping to meet the growing demand for housing in Neath, especially in a location that is close to local amenities, shops, and transportation links.",
                },
                {
                  header: "A Sustainable Future:",
                  text: "The redevelopment will incorporate energy-efficient features, sustainable materials, and eco-friendly design elements that are in line with Castell Group’s commitment to building homes that are environmentally responsible. This transformation is not just about preserving the past; it’s about building for a greener, more sustainable future.",
                },
                {
                  header: "Revitalizing the Local Area:",
                  text: "The project will bring a fresh lease of life to the Wind Street area, providing a boost to local businesses and contributing to the ongoing regeneration of Neath. New residents will bring with them increased demand for services, from cafes and shops to healthcare and schools, which will help to strengthen the town’s economy.",
                },
                {
                  header: "Respecting the Past:",
                  text: "While the Workers’ Club is being transformed into modern apartments, we’re committed to respecting its rich history. Elements of the original building will be preserved and integrated into the development, maintaining the historical character of the site while giving it a new purpose.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Symbol of Neath’s Evolution",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The transformation of Neath’s Workers’ Club into the Wind Street Apartments is a perfect example of how communities evolve. While the industrial era that defined Neath is long gone, the legacy of those who built the town still endures. This redevelopment offers a way to honor that history while creating a space for a new generation of residents to call home.By breathing new life into this historic building, Castell Group is helping to ensure that Neath continues to grow and thrive in the 21st century, while never forgetting the people and stories that have shaped it. The Wind Street Apartments are a testament to the resilience of Neath’s community and the importance of adapting to meet the needs of the future.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Looking Ahead",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As the Wind Street Apartments near completion, we are excited to see how this once-historic site will evolve into a modern, vibrant space for residents. This project symbolizes the ongoing transformation of Neath, where the past meets the future, and where new opportunities for growth and community development are always on the horizon.Interested in learning more about the Wind Street Apartments or Castell Group’s work in Neath? Visit our website or contact us today to discover how we’re helping shape the future of this historic town.",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c023: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Exciting Developments in Oakdale – 26 Affordable Homes on the Way!",
              },
            ],
          },
          {
            text_block: [
              {
                text: "We are thrilled to announce an exciting new project that will bring 26 much-needed affordable homes to the heart of Oakdale, Caerphilly. In partnership with Linc Cymru, we have exchanged contracts for the development at the former New Forge site on Brynhoward Terrace. Supported by a Welsh Government grant, this initiative is set to make a positive impact on the local community.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Transforming the New Forge Site",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The New Forge site has a rich history and is set to embark on a new chapter. The development will feature 26 modern, affordable homes designed to address housing needs in the area. With a blend of practicality and community-focused design, this project promises to enhance the neighborhood's vibrancy.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Community-Centric Approach",
              },
            ],
          },
          {
            text_block: [
              {
                text: "As we prepare to commence work on-site, engaging with the Oakdale community is a top priority. Our goal is to ensure that this project not only provides housing but also integrates seamlessly into the local fabric. We’re keen to hear from residents and collaborate on making this a development that everyone in Oakdale can be proud of.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Sustainability and Safety Considerations",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development adheres to stringent planning regulations, emphasizing safety, environmental sustainability, and community well-being. Measures such as biodiversity enhancements and noise mitigation are integral parts of the planning process. For instance, ecological assessments have been conducted to protect local wildlife, and detailed plans for noise management ensure a harmonious living environment for residents.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      exciting_new_affordable_housing_project_in_oakdale: {
        id: `exciting_new_affordable_housing_project_in_oakdale`,
        no: 1,
        active: true,
        title: `Exciting New Affordable Housing Project in Oakdale`,
        date: `2024-12-13`,
        overview: `We are delighted to announce a transformative development in Oakdale, Caerphilly: the construction of 26 much-needed affordable homes at the former New Forge site on Brynhoward Terrace...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Exciting New Affordable Housing Project in Oakdale",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We are delighted to announce a transformative development in Oakdale, Caerphilly: the construction of 26 much-needed affordable homes at the former New Forge site on Brynhoward Terrace. This exciting project, made possible through a partnership with Linc Cymru and supported by a Welsh Government grant, marks a significant step in addressing local housing needs while enhancing the community.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Breathing New Life Into the New Forge Site",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The New Forge site, a familiar landmark in Oakdale, is set to begin a new chapter. The planned development will introduce 26 modern homes that are thoughtfully designed to provide comfort, practicality, and affordability for families and individuals in the area.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "By repurposing this site, we aim to create a vibrant and welcoming residential space that reflects the needs of the local community while respecting the area’s character and heritage.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Project With the Community at Its Heart",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Community engagement is a cornerstone of this initiative. As we prepare to commence work on-site, we look forward to meeting and collaborating with Oakdale residents to ensure this development delivers positive and lasting benefits for everyone.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We believe that open communication and collaboration are key to the success of this project, and we’re committed to keeping you informed and involved every step of the way.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building With Sustainability and Care",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This development is about more than just housing; it’s about creating a sustainable and harmonious living environment. Key features of the project include:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Ecological Enhancements:",
                  text: "Measures are in place to protect local wildlife and integrate green spaces into the design.",
                },
                {
                  header: "Noise Management:",
                  text: "Comprehensive noise mitigation strategies will ensure a peaceful living environment for future residents and neighbors.",
                },
                {
                  header: "Biodiversity Improvements:",
                  text: "Hedgerow retention and planting will enhance the area’s natural beauty and ecosystem.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "These elements reflect our dedication to thoughtful, sustainable development that benefits both people and the environment.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "What’s Next?",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Construction is set to begin shortly, and we’ll be working diligently to bring this vision to life. Our goal is to have the homes ready for new families as soon as possible while ensuring the highest standards of quality and safety.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We’ll also be sharing regular updates on the project’s progress and organizing opportunities for the community to stay informed and engaged.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Join Us on This Journey",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "We’re excited about the future of this development and the positive impact it will have on Oakdale. If you have any questions, ideas, or feedback, we’d love to hear from you. Let’s work together to make this project a success for the entire community.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Stay tuned for updates as we embark on this exciting journey!",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c026: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Transforming Pontymister: Building Affordable, Sustainable Homes",
              },
            ],
          },
          {
            text_block: [
              {
                text: "At Castell Group, we are proud to be collaborating with Linc Cymru to transform the former Pontymister Petrol Station into 18 much-needed affordable homes in the heart of Caerphilly. Supported by a Welsh Government grant, this project reflects our ongoing commitment to creating sustainable, community-focused developments.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "What’s Being Built?",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The development will feature 18 one-bedroom apartments, each designed to meet modern living standards while ensuring affordability and comfort. These homes are tailored for individuals and couples, providing an excellent solution for those seeking quality, energy-efficient housing. The site will also include shared green spaces, a designated parking area, and convenient on-site amenities such as bike storage. These features not only cater to the practical needs of residents but also foster a sense of community.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A Commitment to Sustainability",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This project goes beyond simply building homes — it’s about making a positive impact on the environment. The apartments are being designed to achieve high energy efficiency, with each unit being EPC A-rated. Here’s how we’re helping the environment:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Renewable Energy:",
                text: "Solar panels will harness clean energy to reduce carbon emissions and lower utility costs for residents.",
              },
              {
                header: "Efficient Heating Solutions:",
                text: "Modern systems, such as electric panel heaters, are being installed alongside air source heat pumps, ensuring environmentally friendly and cost-effective heating.",
              },
              {
                header: "Sustainable Materials:",
                text: "Where possible, we are incorporating materials with a lower environmental footprint into the construction process.",
              },
              {
                header: "Green Spaces:",
                text: "The landscaping around the apartments will include native plants and greenery to encourage biodiversity, creating a home for local wildlife.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Supporting the Community",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This project is more than just bricks and mortar; it’s about addressing the urgent need for affordable housing in the Caerphilly area. By creating high-quality homes at accessible prices, we are supporting individuals and families to live comfortably and sustainably. The redevelopment of this disused petrol station also helps rejuvenate the area, turning an underutilized space into a thriving residential community.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "A Glimpse Into the Future",
              },
            ],
          },
          {
            text_block: [
              {
                text: "As works progress, our team is dedicated to delivering these homes with care and precision, ensuring they meet the highest standards of quality and sustainability. We’re excited to see this site come to life, providing homes that truly make a difference for the environment and the people who will live in them.",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      pontymisters_economy_new_flats_bring_opportunity_and_growth: {
        id: `pontymisters_economy_new_flats_bring_opportunity_and_growth`,
        no: 1,
        active: true,
        title: `Pontymister’s Economy: New Flats Bring Opportunity and Growth`,
        date: `2024-12-13`,
        overview: `Pontymister is on the brink of a transformation with the development of 18 new, affordable one-bedroom flats. Castell Group, in collaboration with Linc Cymru, is redeveloping...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Pontymister’s Economy: New Flats Bring Opportunity and Growth",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Pontymister is on the brink of a transformation with the development of 18 new, affordable one-bedroom flats. Castell Group, in collaboration with Linc Cymru, is redeveloping the former petrol station site into modern, energy-efficient homes. But this project is about much more than just providing housing — it’s an investment in the future of Pontymister, promising economic benefits and encouraging a vibrant, growing community.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Boost to the Local Economy",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The construction of these flats is already generating local jobs, from groundwork and building trades to administrative and planning roles. Local businesses, such as suppliers and contractors, are also benefiting as the project sources materials and services from the surrounding area. This economic activity supports a stronger, more resilient local economy.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Once the flats are complete, the benefits will continue:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Increased Spending Power:",
                  text: "With 18 new households in the area, local shops, cafes, and other businesses can expect a rise in footfall and spending, helping them to thrive.",
                },
                {
                  header: "Attracting New Residents:",
                  text: "These flats are designed to meet the needs of modern living, offering affordability without compromising on quality. Their energy efficiency makes them a particularly attractive option for young professionals, retirees, or individuals seeking a fresh start. This influx of residents will invigorate the local economy with new talent and opportunities.",
                },
                {
                  header: "Support for Local Services:",
                  text: "More residents mean more demand for services such as schools, healthcare, and transport, fostering opportunities for expansion and job creation in these sectors.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Enhancing the Appeal of Pontymister",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This development is part of a broader trend of regeneration in Pontymister, making it a more attractive place to live and work. By turning an unused petrol station into vibrant housing, the project enhances the area’s visual appeal and contributes to a more welcoming environment. The inclusion of green spaces and sustainable features, such as solar panels and modern heating systems, aligns with the growing preference for eco-conscious living. These homes are designed for the future, demonstrating that Pontymister is a place that values sustainability and innovation.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Bridging Housing Gaps",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Affordable housing is a cornerstone of vibrant communities. These flats provide accessible options for people who may otherwise be priced out of the market, helping to retain local talent and bring in new residents who contribute to the area’s growth. For existing residents, the development offers an opportunity for family members or friends to find homes locally, ensuring they can remain close to their support networks.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Catalyst for Future Growth",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "As Pontymister becomes more attractive to residents and businesses alike, it could also draw further investment into the area. Improved infrastructure, growing populations, and a dynamic community make it a prime location for additional development, whether in housing, retail, or other sectors.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Brighter Future for Pontymister",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "With these new flats, Castell Group and Linc Cymru are laying the foundation for a brighter future in Pontymister. By addressing the need for affordable, modern housing, they’re fostering economic growth and setting the stage for a more vibrant, inclusive community. As the development progresses, the buzz around Pontymister grows — and so does its potential. This project is more than just a building; it’s a catalyst for long-term prosperity.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Keep an eye on Pontymister as it evolves into a hub of opportunity and growth!",
                },
              ],
            },
          ],
        ],
      },
      building_homes_for_the_future: {
        id: `building_homes_for_the_future`,
        no: 1,
        active: true,
        title: `Building Homes for the Future`,
        date: `2024-12-13`,
        overview: `Pontymister is undergoing an exciting transformation as Castell Group, in partnership with Linc Cymru, builds 18 affordable one-bedroom flats on the site of the former petrol station on Newport Road...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building Homes for the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Pontymister is undergoing an exciting transformation as Castell Group, in partnership with Linc Cymru, builds 18 affordable one-bedroom flats on the site of the former petrol station on Newport Road. This project isn’t just about creating homes; it’s about doing so in a way that sets a new standard for construction. Using innovative methods and sustainable practices, this development stands out as a model for modern housing projects.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building with a Purpose",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The site in Pontymister has a unique history, previously serving as a petrol station. This posed specific challenges, such as remediating the ground due to historic contamination and integrating modern infrastructure into a compact, urban site. To address these challenges, the team has employed a range of forward-thinking approaches:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Site Preparation and Ground Remediation:",
                  text: "Before construction began, the site underwent a thorough environmental assessment. Contaminants in the soil, typical of former petrol stations, were carefully treated and removed, ensuring the site is safe for residential use. Advanced geotechnical investigations also informed the design of stable foundations suited to the site’s complex ground conditions",
                },
                {
                  header: "Efficient Foundation Solutions:",
                  text: "Due to the presence of dense gravel and made ground, the team opted for pad foundations, a solution that minimizes ground disturbance while providing a robust base for the buildings. This approach reduces waste and construction time compared to more invasive piling methods",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Modern Methods of Construction (MMC)",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "What truly sets this project apart is the use of modern construction techniques designed to save time, reduce waste, and ensure consistent quality:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Prefabricated Components:",
                  text: "Many of the structural elements are manufactured off-site and then assembled on-site. This ensures precision, reduces on-site labor, and minimizes disruption to the surrounding area.",
                },
                {
                  header: "Sustainable Materials:",
                  text: "The project uses materials with a lower environmental impact, aligning with the commitment to sustainability. These include high-performance insulation and durable finishes that ensure the homes meet modern energy-efficiency standards.",
                },
                {
                  header: "Fabric-First Approach:",
                  text: "The buildings are designed with high-quality insulation and airtight construction, ensuring minimal energy loss and reducing reliance on heating systems.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Advanced Energy Systems",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The Pontymister flats are being built to be future-proof. Solar panels and air source heat pumps will provide renewable energy, while electric heating systems ensure comfort without the need for gas. This not only lowers the environmental impact but also helps residents save on energy bills",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "How It Differs from Other Sites",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Unlike traditional developments, which often rely on older, more resource-intensive methods, the Pontymister project embraces innovation at every stage:",
                },
              ],
            },
            {
              bullet_points: [
                {
                  header: "Remediation and Reuse:",
                  text: "Many sites with a history of industrial use are left untouched due to the challenges of remediation. This project demonstrates how such land can be transformed responsibly and sustainably.",
                },
                {
                  header: "Compact Design:",
                  text: "The development maximizes the use of a relatively small footprint while creating spacious, comfortable homes. The integration of shared spaces like bike storage and green areas adds value for residents.",
                },
                {
                  header: "Sustainability as Standard:",
                  text: "While some developments treat sustainability as an add-on, it’s at the heart of this project, from the materials used to the energy systems installed.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A New Standard for Housing",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The construction methods and design choices being employed in Pontymister are more than just practical solutions; they’re a glimpse into the future of housing. Castell Group and Linc Cymru are proving that it’s possible to build homes that are affordable, sustainable, and innovative while addressing the challenges of modern construction. This isn’t just a development; it’s a demonstration of what’s possible when thoughtful planning meets cutting-edge methods. As the project takes shape, it’s setting a benchmark for other sites across Wales and beyond.",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Stay tuned for updates as these exceptional homes take form!",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c030: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Castell Group Development Blog: Sustainable Living at the Former YMCA Porth",
              },
              {
                main: false,
                text: "Revitalizing a Historic Site for Modern Living",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Castell Group is proud to announce that construction is underway at the former YMCA site in Porth. This project involves the transformation of a previously derelict location into a vibrant residential community comprising 21 one-bedroom EPC A-rated homes. The development is designed to address local housing needs while incorporating sustainable and eco-conscious features.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Key Features of the Development",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "EPC A-Rated Homes",
                text: "All dwellings will achieve the highest level of energy efficiency, significantly reducing energy costs for residents and promoting sustainability.",
              },
              {
                header: "Sustainable Drainage Systems (SuDS)",
                text: "To manage surface water and prevent flooding, the project includes state-of-the-art SuDS. This ensures compliance with local environmental standards while protecting the surrounding community.",
              },
              {
                header: "Landscaping for Aesthetic and Environmental Value",
                text: "The development includes thoughtful landscaping to enhance its visual appeal and provide green spaces that support local biodiversity.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Infrastructure Enhancements",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Onsite Vehicle Parking:",
                text: "Safe and accessible parking facilities for residents.",
              },
              {
                header: "Improved Access Roads:",
                text: "Enhancing connectivity and ensuring safety for both pedestrians and vehicles.",
              },
              {
                header: "Cycling Amenities:",
                text: "Secure storage for bicycles encourages sustainable transport.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Collaboration with Local Partners",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Castell Group is working closely with a local Registered Social Landlord to deliver these homes, ensuring they reach those in greatest need.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Sustainability and Community Impact",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Our commitment to sustainability extends beyond energy efficiency:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Reduced Carbon Footprint:",
                text: "The use of sustainable materials and innovative construction techniques aligns with Castell’s green building practices.",
              },
              {
                header: "Community Integration:",
                text: "By regenerating the site, we aim to create a positive impact on the local area, blending seamlessly with the existing urban environment.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Construction Management",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The redevelopment process follows strict environmental and safety standards:",
              },
            ],
          },
          {
            bullet_points: [
              {
                header: "Dust and Noise Control Measures",
                text: "Advanced techniques, such as water damping and noise monitoring, ensure minimal disruption to the neighborhood",
              },
              {
                header: "Waste Management",
                text: "Nearly 99% of construction waste will be recycled, showcasing Castell’s commitment to reducing landfill impact",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Compliance with Local Regulations",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The project adheres to all pre-approved construction and planning guidelines to ensure efficiency and safety throughout",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Looking Ahead",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The YMCA Porth redevelopment is more than just a housing project; it’s a step toward sustainable living and community enhancement. Castell Group is honored to contribute to Porth’s legacy by breathing new life into this historic site while keeping an eye on the future.",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Stay tuned for updates on the progress of this exciting project!",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      from_ruins_to_renewal: {
        id: `from_ruins_to_renewal`,
        no: 1,
        active: true,
        title: `From Ruins to Renewal: Transforming the Former YMCA Site in Porth`,
        date: `2024-12-13`,
        overview: `In 2008, a devastating fire engulfed the historic YMCA building on Eirw Road, Porth. Firefighters from across South Wales battled the inferno for over 12 hours, but the blaze...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "From Ruins to Renewal: Transforming the Former YMCA Site in Porth",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "In 2008, a devastating fire engulfed the historic YMCA building on Eirw Road, Porth. Firefighters from across South Wales battled the inferno for over 12 hours, but the blaze left the century-old structure gutted and beyond repair. Once a cornerstone of the community, the site stood dormant for years, a stark reminder of its tragic past. Today, Castell Group is proud to breathe new life into this storied location. We have acquired the former YMCA site and commenced work on a transformative residential development that will provide much-needed affordable housing for the local community.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building Homes, Building Futures",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Our vision for the site is a development of 21 EPC A-rated, one-bedroom dwellings designed with sustainability, comfort, and community at their core. These homes are not just about bricks and mortar—they are about creating opportunities for individuals and families to thrive in a space that is both modern and environmentally friendly. To achieve this, we’ve integrated sustainable drainage systems, landscape planting, and efficient vehicle parking solutions, ensuring the project is as eco-conscious as it is functional.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Commitment to Community",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This project is more than a development; it’s a partnership with a local registered social landlord to deliver homes that address the pressing housing needs of the area. By focusing on high standards of energy efficiency, these dwellings will significantly reduce energy costs for future residents, promoting both financial and environmental sustainability.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Honoring the Past, Embracing the Future",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The redevelopment of the YMCA site is a testament to Castell Group’s dedication to revitalizing communities and repurposing spaces with purpose. While the fire in 2008 marked the end of an era, this project symbolizes a new beginning—a legacy of resilience, renewal, and hope. We are honored to play a part in Porth’s journey, transforming a site once scarred by tragedy into a beacon of opportunity and sustainability. Stay tuned for updates as we continue to build homes, strengthen communities, and create a brighter future.",
                },
              ],
            },
          ],
        ],
      },
    },
  },
  c037: {
    overview: {
      sections: [
        [
          {
            headings: [
              {
                main: true,
                text: "Bettws Social Club",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Exciting progress is underway at the former Bettws Social Club site in Bridgend! We’re building 20 affordable, energy-efficient one-bedroom flats, designed to provide much-needed social housing for the community.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Project Overview",
              },
            ],
          },
          {
            text_block: [
              {
                text: "This redevelopment includes two blocks: Block 1: 9 flats with communal access and 2 walk-up flats. Block 2: 9 flats with communal access. The project also features sustainable drainage, landscaped spaces, 21 parking spots, and secure bicycle storage for residents.",
              },
            ],
          },
        ],

        [
          {
            headings: [
              {
                main: true,
                text: "Supporting Local Wildlife",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Ecological surveys of the site revealed moderate potential for bat roosting, but no bats or nesting birds were found. To support the surrounding environment, we are implementing measures to enhance biodiversity:",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Bat Boxes",
              },
            ],
          },
          {
            text_block: [
              {
                text: "We will be installing bat boxes on the new buildings to provide safe habitats for local species such as the Common Pipistrelle.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Bird Nesting Areas",
              },
            ],
          },
          {
            text_block: [
              {
                text: "We will be adding sparrow terraces, swift boxes, and house martin nests to encourage a thriving bird population.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Native Planting",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Incorporating hedgerows and wildflowers will connect the development with nearby wildlife corridors and enhance habitats for birds, insects, and small mammals.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Eco-Friendly Lighting",
              },
            ],
          },
          {
            text_block: [
              {
                text: "Using low-level, motion-sensor lighting will avoid disrupting nocturnal wildlife, especially bats.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Thoughtful Design for Wildlife and Residents",
              },
            ],
          },
          {
            text_block: [
              {
                text: "The site is close to natural features like woodlands and hedgerows, providing excellent foraging and commuting routes for bats, birds, and other species. Our landscaping will focus on maintaining these connections while creating new green spaces that benefit both residents and wildlife.",
              },
            ],
          },
        ],
        [
          {
            headings: [
              {
                main: true,
                text: "Project Information",
              },
            ],
          },
          {
            text_block: [
              {
                text: "With high demand for one-bedroom flats in the area, this project meets a critical need identified by Bridgend Council. We’re working with Valleys to Coast to ensure these homes support those most in need.",
              },
              {
                text: "Scheduled for completion by April 2026, the project is partly funded through the Social Housing Grant.",
              },
              {
                text: "We’re thrilled to bring this vision to life and make a meaningful difference for the Bridgend community. Stay tuned for updates!",
              },
            ],
          },
        ],
      ],
    },
    milestones: {
      building_a_brighter_future_with_affordable_housing: {
        id: `building_a_brighter_future_with_affordable_housing`,
        no: 1,
        active: true,
        title: `Building a Brighter Future with Affordable Housing`,
        date: `2024-12-13`,
        overview: `The village of Bettws is taking a bold step forward with the approval of plans to transform the site of the former Bettws Social Club into 20 affordable one-bedroom apartments...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Building a Brighter Future with Affordable Housing",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "The village of Bettws is taking a bold step forward with the approval of plans to transform the site of the former Bettws Social Club into 20 affordable one-bedroom apartments. This development represents a crucial opportunity to address the housing crisis and bring new energy to the community.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Solution to the Housing Shortage",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "With Bridgend County facing a significant housing shortfall, this project couldn’t come at a better time. There are currently over 2,300 applicants on the housing waiting list—an increase of nearly 40% from last year. This development directly tackles the issue by providing much-needed affordable homes for individuals and families in need.",
                },
                {
                  text: "The two new blocks will create a safe and welcoming space for residents, enabling people to return to their hometown and build their lives close to family, work, and familiar surroundings.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A New Beginning for Bettws",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "While the Bettws Social Club held a special place in the village’s history, its deteriorating condition meant it was no longer viable as a community facility. Repurposing this site for affordable housing ensures the land continues to benefit the local population.",
                },
              ],
            },
          ],
          [
            {
              headings: [{ main: true, text: "Bettws: Leading the Way" }],
            },
            {
              text_block: [
                {
                  text: "As part of a broader housing strategy, this project reflects the council’s commitment to increasing the supply of suitable homes, improving housing accessibility, and reducing homelessness. With the backing of key stakeholders like Valleys2Coast and Castell Group, the development is a testament to what communities can achieve when they come together to tackle pressing social issues.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Brighter Future for All",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Bettws is not just building homes; it’s building hope. This development symbolizes progress, resilience, and the promise of a stronger, more inclusive future. By addressing today’s housing challenges, Bettws is creating a village where everyone has the opportunity to thrive. Stay tuned as this exciting transformation unfolds, bringing positive change to Bettws and beyond. Together, we’re building a brighter future!",
                },
              ],
            },
          ],
        ],
      },
      our_aims_for_bettws: {
        id: `our_aims_for_bettws`,
        no: 2,
        active: false,
        title: `Our Aims for Bettws`,
        date: `2024-12-13`,
        overview: `Castell Group is proud to announce its latest transformative project: the purchase and revitalisation of the Bettws Social Club in Bridgend. This building, once in a state of...`,
        image: {
          image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[2]),
          image_alt: `?`,
        },
        sections: [
          [
            {
              image: {
                image_url: insertTextbeforeUrlExtention(`${template_image_dark}`, res_types[3]),
                image_alt: `?`,
              },
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Castell Group’s Vision for Community and Sustainability ",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "Castell Group is proud to announce its latest transformative project: the purchase and revitalisation of the Bettws Social Club in Bridgend. This building, once in a state of disrepair, holds great potential for the local community, and Castell Group is committed to restoring it as a vibrant hub for future generations.",
                },
                {
                  text: "When Castell Group acquired the Bettws Social Club, we saw more than just a rundown property—we saw an opportunity to create a lasting, positive impact on the Bettws community. Our team was inspired by the potential to restore this key local landmark and transform it into a space that serves the diverse needs of residents, offering not only affordable housing but a welcoming venue for social activities and community engagement.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Commitment to Community-Focused Development",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "At Castell Group, we believe in the power of community-driven projects. With our plans for the Bettws Social Club, we will provide 20 affordable one-bedroom flats in an area that has long faced housing shortages. Our development will offer new homes for local people while enhancing the community spirit that once made it a local gathering place. This project isn’t just about creating new homes—it’s about revitalising a cherished community asset. ",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "Transforming with Purpose",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "In addition to the residential development, Castell Group is taking a comprehensive approach to ensure the building's future is sustainable. We are committed to minimising environmental impact through eco-friendly design and infrastructure, including green energy solutions and biodiversity-enhancing landscaping. We’re not just fixing a roof—we’re creating a space that enriches the lives of everyone in Bettws, today and tomorrow.",
                },
              ],
            },
          ],
          [
            {
              headings: [
                {
                  main: true,
                  text: "A Bright Future Ahead",
                },
              ],
            },
            {
              text_block: [
                {
                  text: "This project is part of Castell Group’s broader commitment to community regeneration. We understand the importance of balancing modern housing with community needs. By revitalising the Bettws Social Club, we are not only providing homes but ensuring that Bettws remains a thriving, resilient community for generations to come. We look forward to updating the community as work progresses and to celebrating the future of Bettws Social Club as a place where both people and nature can thrive. Together, we’re building a brighter, more sustainable future.",
                },
              ],
            },
          ],
        ],
      },
    },
  },
};

export { project_information_data };
