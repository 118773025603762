import { image_url_prefix } from "./config.js";

const department_data = {
  executive: {
    id: "executive",
    name: "Executive",
  },
  administration: {
    id: "administration",
    name: "Administration",
  },
  sheq: {
    id: "sheq",
    name: "SHEQ",
  },
  it: {
    id: "it",
    name: "IT",
  },
  marketing: {
    id: "marketing",
    name: "Marketing",
  },
  land_and_development: {
    id: "land_and_development",
    name: "Land and Development",
  },
  commercial: {
    id: "commercial",
    name: "Commercial",
  },
  technical: {
    id: "technical",
    name: "Technical",
  },
  mep: {
    id: "mep",
    name: "MEP",
  },
  groundworks: {
    id: "groundworks",
    name: "Groundworks",
  },
  operations: {
    id: "operations",
    name: "Operations",
  },
  finance: {
    id: "finance",
    name: "Finance",
  },
  recruitment: {
    id: "recruitment",
    name: "Recruitment",
  },
};

const staff_data = {
  S00000: {
    employee_number: "S00000",
    first_name: "Dorian",
    last_name: "Payne",
    job_title: "Managing Director",
    department: department_data.executive,
    main_image_url: `${image_url_prefix}3-0000-1.jpg`,
    image_urls: [`${image_url_prefix}3-0000-1.jpg`],
  },
  S10000: {
    employee_number: "S10000",
    first_name: "Clive",
    last_name: "Hill",
    job_title: "Non-Executive Director",
    department: department_data.executive,
    main_image_url: "",
    image_urls: [],
  },
  S00001: {
    employee_number: "S00001",
    first_name: "Ethan",
    last_name: "Powell",
    job_title: "Systems Development Manager",
    department: department_data.it,
    main_image_url: `${image_url_prefix}3-0001-1.jpg`,
    image_urls: [`${image_url_prefix}3-0001-1.jpg`, `${image_url_prefix}3-0001-2.jpg`],
  },
  S00023: {
    employee_number: "S00023",
    first_name: "Kevin",
    last_name: "Roberts",
    job_title: "Head of Construction",
    department: department_data.operations,
    main_image_url: `${image_url_prefix}3-0023-1.jpg`,
    image_urls: [`${image_url_prefix}3-0023-1.jpg`],
  },
  S00037: {
    employee_number: "S00037",
    first_name: "Sion",
    last_name: "Kinsey",
    job_title: "Project Manager",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  S00111: {
    employee_number: "S00111",
    first_name: "Phil",
    last_name: "Hare",
    job_title: "Contracts Manager",
    department: department_data.operations,
    main_image_url: `${image_url_prefix}3-0111-1.png`,
    image_urls: [`${image_url_prefix}3-0111-1.png`],
  },
  S00112: {
    employee_number: "S00112",
    first_name: "Ben",
    last_name: "Hall",
    job_title: "Contracts Manager",
    department: department_data.operations,
    main_image_url: `${image_url_prefix}3-0112-1.png`,
    image_urls: [`${image_url_prefix}3-0112-1.png`],
  },
  S00006: {
    employee_number: "S00006",
    first_name: "Ashleigh",
    last_name: "Davies",
    job_title: "Telehandler / Labourer",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  S00012: {
    employee_number: "S00012",
    first_name: "Dyfan",
    last_name: "Williams",
    job_title: "Head of Land and Development",
    department: department_data.land_and_development,
    main_image_url: `${image_url_prefix}3-0012-1.jpg`,
    image_urls: [`${image_url_prefix}3-0012-1.jpg`],
  },
  S00013: {
    employee_number: "S00013",
    first_name: "Donna",
    last_name: "Rosser",
    job_title: "Commercial Manager",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0013-1.jpg`,
    image_urls: [`${image_url_prefix}3-0013-1.jpg`],
  },
  S00014: {
    employee_number: "S00014",
    first_name: "Matthew",
    last_name: "Evans",
    job_title: "Head of Technical",
    department: department_data.technical,
    main_image_url: `${image_url_prefix}3-0014-3.jpg`,
    image_urls: [
      `${image_url_prefix}3-0014-1.png`,
      `${image_url_prefix}3-0014-2.jpg`,
      `${image_url_prefix}3-0014-3.jpg`,
    ],
  },
  S00096: {
    employee_number: "S00096",
    first_name: "Craig",
    last_name: "Weymouth",
    job_title: "Senior Technical Manager",
    department: department_data.technical,
    main_image_url: `${image_url_prefix}3-0096-1.jpg`,
    image_urls: [`${image_url_prefix}3-0096-1.jpg`],
  },
  S00105: {
    employee_number: "S00105",
    first_name: "Emma",
    last_name: "Howells",
    job_title: "Finance Director",
    department: department_data.finance,
    main_image_url: `${image_url_prefix}3-0105-1.png`,
    image_urls: [`${image_url_prefix}3-0105-1.png`],
  },
  S00017: {
    employee_number: "S00017",
    first_name: "Aled",
    last_name: "Hopkins MAAT",
    job_title: "Junior Financial Controller",
    department: department_data.finance,
    main_image_url: `${image_url_prefix}3-0017-1.jpg`,
    image_urls: [`${image_url_prefix}3-0017-1.jpg`],
  },
  S00026: {
    employee_number: "S00026",
    first_name: "Felix",
    last_name: "Hoare",
    job_title: "Managing Quantity Surveyor",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0026-1.jpg`,
    image_urls: [`${image_url_prefix}3-0026-1.jpg`, `${image_url_prefix}3-0026-2.png`],
  },
  S00032: {
    employee_number: "S00032",
    first_name: "Mark",
    last_name: "Hughes",
    job_title: "Buyer",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0032-1.jpg`,
    image_urls: [`${image_url_prefix}3-0032-1.jpg`],
  },
  S00033: {
    employee_number: "S00033",
    first_name: "Anthony",
    last_name: "Flood",
    job_title: "Site Manager",
    department: department_data.operations,
    main_image_url: `${image_url_prefix}3-0033-1.jpg`,
    image_urls: [`${image_url_prefix}3-0033-1.jpg`],
  },
  S00034: {
    employee_number: "S00034",
    first_name: "Huw",
    last_name: "Jenkins",
    job_title: "Site Manager",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  S00036: {
    employee_number: "S00036",
    first_name: "Jason",
    last_name: "Hook",
    job_title: "Site Manager",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  S00038: {
    employee_number: "S00038",
    first_name: "Lyndsey",
    last_name: "Holmes",
    job_title: "Office Manager",
    department: department_data.administration,
    main_image_url: `${image_url_prefix}3-0038-1.jpg`,
    image_urls: [`${image_url_prefix}3-0038-1.jpg`],
  },
  S00041: {
    employee_number: "S00041",
    first_name: "Gareth",
    last_name: "Boaler",
    job_title: "Site Manager",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  S00042: {
    employee_number: "S00042",
    first_name: "Martin",
    last_name: "Pretty",
    job_title: "Senior Technical Manager",
    department: department_data.technical,
    main_image_url: `${image_url_prefix}3-0042-1.jpg`,
    image_urls: [`${image_url_prefix}3-0042-1.jpg`],
  },
  S00045: {
    employee_number: "S00045",
    first_name: "Chloe",
    last_name: "Clarke",
    job_title: "Technical Coordinator",
    department: department_data.technical,
    main_image_url: `${image_url_prefix}3-0045-1.jpg`,
    image_urls: [`${image_url_prefix}3-0045-1.jpg`],
  },
  S00048: {
    employee_number: "S00048",
    first_name: "Glenn",
    last_name: "Stroud",
    job_title: "Groundworks & Civils Manager",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00049: {
    employee_number: "S00049",
    first_name: "Jenny",
    last_name: "Stroud",
    job_title: "Trainee Quantity Surveyor",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0049-1.jpg`,
    image_urls: [`${image_url_prefix}3-0049-1.jpg`],
  },
  S00050: {
    employee_number: "S00050",
    first_name: "Lee",
    last_name: "Hathway",
    job_title: "Site Supervisor (Groundworks)",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00051: {
    employee_number: "S00051",
    first_name: "Kieran",
    last_name: "Rees",
    job_title: "Trainee Accounts Assistant",
    department: department_data.finance,
    main_image_url: `${image_url_prefix}3-0051-1.png`,
    image_urls: [`${image_url_prefix}3-0051-1.png`],
  },
  S00053: {
    employee_number: "S00053",
    first_name: "Huw",
    last_name: "Tucker",
    job_title: "Groundworks Supervisor",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00054: {
    employee_number: "S00054",
    first_name: "Callum",
    last_name: "Tucker",
    job_title: "Groundworker Operative",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00055: {
    employee_number: "S00055",
    first_name: "Louis",
    last_name: "Moore",
    job_title: "Groundworker",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00056: {
    employee_number: "S00056",
    first_name: "William",
    last_name: "Francis",
    job_title: "Groundworker Operative",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  // S00057: {
  //   employee_number: "S00057",
  //   first_name: "Jac",
  //   last_name: "Jones",
  //   job_title: "Telehandler / Labourer",
  //   department: department_data.operations,
  //   main_image_url: "",
  //   image_urls: [],
  // },
  S00058: {
    employee_number: "S00058",
    first_name: "Martin",
    last_name: "Morgan",
    job_title: "Skilled Operative",
    department: department_data.operations,
    main_image_url: "",
    image_urls: [],
  },
  // S00059: {
  //   employee_number: "S00059",
  //   first_name: "Mark",
  //   last_name: "Gammaidoni",
  //   job_title: "Contracts Manager",
  //   department: department_data.operations,
  //   main_image_url: `${image_url_prefix}3-0059-1.jpg`,
  //   image_urls: [`${image_url_prefix}3-0059-1.jpg`],
  // },
  S00062: {
    employee_number: "S00062",
    first_name: "Liam",
    last_name: "Burder",
    job_title: "Trainee Buyer",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0062-1.jpg`,
    image_urls: [`${image_url_prefix}3-0062-1.jpg`],
  },
  S00064: {
    employee_number: "S00064",
    first_name: "Nigal",
    last_name: "Evans",
    job_title: "Groundworker / Machine Operative",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00065: {
    employee_number: "S00065",
    first_name: "Robert",
    last_name: "Lawson",
    job_title: "Groundworker Operative",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00066: {
    employee_number: "S00066",
    first_name: "Harkeerat",
    last_name: "Singh",
    job_title: "Technical Apprentice",
    department: department_data.technical,
    main_image_url: `${image_url_prefix}3-0066-1.jpg`,
    image_urls: [`${image_url_prefix}3-0066-1.jpg`],
  },
  S00067: {
    employee_number: "S00067",
    first_name: "Mia",
    last_name: "Dixey",
    job_title: "Marketing Executive",
    department: department_data.marketing,
    main_image_url: `${image_url_prefix}3-0067-1.jpg`,
    image_urls: [`${image_url_prefix}3-0067-1.jpg`],
  },
  S00070: {
    employee_number: "S00070",
    first_name: "Llyr",
    last_name: "Morris",
    job_title: "Development and Planning Manager",
    department: department_data.land_and_development,
    main_image_url: `${image_url_prefix}3-0070-1.jpg`,
    image_urls: [`${image_url_prefix}3-0070-1.jpg`],
  },
  S00073: {
    employee_number: "S00073",
    first_name: "Andrew",
    last_name: "Griffiths",
    job_title: "Estimator",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0073-1.jpg`,
    image_urls: [`${image_url_prefix}3-0073-1.jpg`],
  },
  S00074: {
    employee_number: "S00074",
    first_name: "Rhys",
    last_name: "Whalley",
    job_title: "Land Manager",
    department: department_data.land_and_development,
    main_image_url: `${image_url_prefix}3-0074-1.jpg`,
    image_urls: [`${image_url_prefix}3-0074-1.jpg`],
  },
  S00075: {
    employee_number: "S00075",
    first_name: "Llyr",
    last_name: "Green",
    job_title: "Site Engineer",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  // S00088: {
  //   employee_number: "S00088",
  //   first_name: "Rhianna",
  //   last_name: "Davies",
  //   job_title: "Business Admin Apprentice",
  //   department: department_data.administration,
  //   main_image_url: `${image_url_prefix}3-0088-2.jpg`,
  //   image_urls: [`${image_url_prefix}3-0088-2.jpg`],
  // },
  // S00090: {
  //   employee_number: "S00090",
  //   first_name: "Alex",
  //   last_name: "Sims",
  //   job_title: "Assistant Site Manager",
  //   department: department_data.operations,
  //   main_image_url: "",
  //   image_urls: [],
  // },
  S00091: {
    employee_number: "S00091",
    first_name: "Thomas",
    last_name: "Murphy",
    job_title: "Marketing Apprentice",
    department: department_data.marketing,
    main_image_url: `${image_url_prefix}3-0091-2.jpg`,
    image_urls: [`${image_url_prefix}3-0091-2.jpg`],
  },
  // S00094: {
  //   employee_number: "S00094",
  //   first_name: "Sharmin",
  //   last_name: "Miah",
  //   job_title: "Finance Apprentice",
  //   department: department_data.finance,
  //   main_image_url: `${image_url_prefix}3-0094-1.jpg`,
  //   image_urls: [`${image_url_prefix}3-0094-1.jpg`],
  // },
  S00097: {
    employee_number: "S00097",
    first_name: "Joseph",
    last_name: "Howells",
    job_title: "Finance Apprentice",
    department: department_data.finance,
    main_image_url: `${image_url_prefix}3-0097-1.jpg`,
    image_urls: [`${image_url_prefix}3-0097-1.jpg`],
  },
  S00098: {
    employee_number: "S00098",
    first_name: "Thomas",
    last_name: "Evans",
    job_title: "Land and Development Graduate",
    department: department_data.land_and_development,
    main_image_url: `${image_url_prefix}3-0098-1.jpg`,
    image_urls: [`${image_url_prefix}3-0098-1.jpg`],
  },
  S00099: {
    employee_number: "S00099",
    first_name: "Adele",
    last_name: "John",
    job_title: "SHEQ Manager",
    department: department_data.sheq,
    main_image_url: `${image_url_prefix}3-0099-1.jpg`,
    image_urls: [`${image_url_prefix}3-0099-1.jpg`],
  },
  S00100: {
    employee_number: "S00100",
    first_name: "James",
    last_name: "Davies",
    job_title: "MEP Lead",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0100-2.png`,
    image_urls: [`${image_url_prefix}3-0100-2.png`],
  },
  S00101: {
    employee_number: "S00101",
    first_name: "Alex",
    last_name: "Read",
    job_title: "M&E Estimator",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0101-1.png`,
    image_urls: [`${image_url_prefix}3-0101-1.png`],
  },
  // S00102: {
  //   employee_number: "S00102",
  //   first_name: "Nolan",
  //   last_name: "Reed",
  //   job_title: "Site Manager",
  //   department: department_data.operations,
  //   main_image_url: "",
  //   image_urls: [],
  // },
  S00103: {
    employee_number: "S00103",
    first_name: "Joanne",
    last_name: "McCormack",
    job_title: "Admin Assistant",
    department: department_data.administration,
    main_image_url: "",
    image_urls: [],
  },
  S00104: {
    employee_number: "S00104",
    first_name: "Tom",
    last_name: "Crawley",
    job_title: "Trainee Quantity Surveyor",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0104-1.png`,
    image_urls: [`${image_url_prefix}3-0104-1.jpg`],
  },
  S00106: {
    employee_number: "S00106",
    first_name: "Tom",
    last_name: "Williams",
    job_title: "Site Supervisor (Groundworks)",
    department: department_data.groundworks,
    main_image_url: "",
    image_urls: [],
  },
  S00107: {
    employee_number: "S00107",
    first_name: "Nathan",
    last_name: "Wallace",
    job_title: "M&E Supervisor",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0107-1.png`,
    image_urls: [`${image_url_prefix}3-0107-1.png`],
  },
  S00108: {
    employee_number: "S00108",
    first_name: "Joshua",
    last_name: "Norris",
    job_title: "Plumber",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0108-1.png`,
    image_urls: [`${image_url_prefix}3-0108-1.png`],
  },
  S00118: {
    employee_number: "S00118",
    first_name: "Josh",
    last_name: "Watkins",
    job_title: "Electrician",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0118-1.png`,
    image_urls: [`${image_url_prefix}3-0118-1.png`],
  },
  S00109: {
    employee_number: "S00109",
    first_name: "Connor",
    last_name: "Davies",
    job_title: "Digital Content Creator",
    department: department_data.marketing,
    main_image_url: `${image_url_prefix}3-0109-2.jpg`,
    image_urls: [`${image_url_prefix}3-0109-1.png`, `${image_url_prefix}3-0109-2.jpg`],
  },
  S00110: {
    employee_number: "S00110",
    first_name: "Ben",
    last_name: "Fox",
    job_title: "Senior Quantity Surveyor",
    department: department_data.commercial,
    main_image_url: "",
    image_urls: [],
  },
  S00113: {
    employee_number: "S00113",
    first_name: "Brad",
    last_name: "Salt",
    job_title: "Head of Recruitment",
    department: department_data.recruitment,
    main_image_url: "",
    image_urls: [],
  },
  S00114: {
    employee_number: "S00114",
    first_name: "Olivia",
    last_name: "Adams",
    job_title: "Trainee Quantity Surveyor",
    department: department_data.commercial,
    main_image_url: `${image_url_prefix}3-0114-1.png`,
    image_urls: [`${image_url_prefix}3-0114-1.png`],
  },
  S00115: {
    employee_number: "S00115",
    first_name: "Emily",
    last_name: "Kervin",
    job_title: "Plumbing Apprentice",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0115-1.png`,
    image_urls: [`${image_url_prefix}3-0115-1.png`],
  },
  S00116: {
    employee_number: "S00116",
    first_name: "Sam",
    last_name: "Morgan",
    job_title: "Electrical Apprentice",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0116-1.png`,
    image_urls: [`${image_url_prefix}3-0116-1.png`],
  },
  S00117: {
    employee_number: "S00117",
    first_name: "Jack",
    last_name: "Hoskins",
    job_title: "Electrical Apprentice",
    department: department_data.mep,
    main_image_url: `${image_url_prefix}3-0117-1.png`,
    image_urls: [`${image_url_prefix}3-0117-1.png`],
  },
  S00119: {
    employee_number: "S00119",
    first_name: "Jasmin",
    last_name: "Maunder",
    job_title: "Content Creator Apprentice",
    department: department_data.marketing,
    main_image_url: `${image_url_prefix}3-0119-1.png`,
    image_urls: [`${image_url_prefix}3-0119-1.png`],
  },
};

export { department_data, staff_data };
